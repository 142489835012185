import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import { useNavigate, useLocation } from 'react-router';
import { Button } from 'semantic-ui-react'


const Sequence = [
    {
        no: 1,
        name: "Services",
        value: "services",
        link: "onboarding/services"
    },
    {
        no: 2,
        name: "White Label",
        value: "white-label",
        link: "onboarding/white-label"
    },
    {
        no: 3,
        name: "Category",
        value: "category",
        link: "onboarding/category"
    },
    {
        no: 4,
        name: "Template",
        value: "email-template",
        link: "onboarding/email-template"
    },
    {
        no: 5,
        name: "Questions",
        value: "questions",
        link: "onboarding/questions"
    },
    {
        no: 6,
        name: "Survey (tokens)",
        value: "emails",
        link: "onboarding/emails"
    },
    {
        no: 7,
        name: "Survey Customizations",
        value: "survey-customizations",
        link: "onboarding/survey-customizations"
    }
]

const NavigationBar = ({organizationId}) => {
    const navigate = useNavigate()
    const location = useLocation()
    let urlElements = location.pathname.split('/')
    

    const [urlLocation, setUrlLocation] = useState(false)
    const [showHome, setShowHome] = useState(false)

    useEffect(() => {
        setShowHome(false)
        setUrlLocation(false)
        const LastElement = urlElements[urlElements.length - 1]

        if (!Number.isInteger(Number(LastElement))) {
            setShowHome(true)
            setUrlLocation(LastElement)
        }

    }, [urlElements]);

 
    const Link = `/app/organizations/details/${organizationId}`

    if (!organizationId) {
        navigate("/app/organizations")
    }

    // console.log(urlLocation)

    return (<TopBar>
        {showHome && <BarBoxes>
            <Button
                onClick={() =>
                    navigate(`${Link}`)}>
                Home
            </Button>
        </BarBoxes> }
        {Sequence.map((bx, i) => {
            return (
                <BarBoxes key={i}>
                    <Button
                        color={bx.value === urlLocation ?'violet' : 'grey'}
                        onClick={() =>
                            navigate(`${Link}/${bx.link}`)}>
                        {bx.name}
                    </Button>
                </BarBoxes>
            )

        })}


    </TopBar>
    );
};

export default NavigationBar;

const TopBar = styled.div`
    display: flex; 
    justify-content: flex-start
`

const BarBoxes = styled.div`
    padding: 5px;
    margin: 5px;
`