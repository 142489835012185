import {
    GET_RECOMMENDATION_MODELS_PENDING,
    GET_RECOMMENDATION_MODELS,
    CLEAR_RECOMMENDATION_MODELS,
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    recommendation_models: [],
    pending: false,
    pageNumber: 1,
  };
  

export default function RecommendationModelRating(state = initialState, action) {
    switch (action.type) {

      case GET_RECOMMENDATION_MODELS_PENDING:
        return {
          ...state,
          pending: true,
          updated: false,
        };
      case GET_RECOMMENDATION_MODELS:
        return {
          ...state,
          recommendation_models: action.payload,
          pending: false,
          updated: false,
        };

      case CLEAR_RECOMMENDATION_MODELS:
        return {
          ...state,
          ...initialState,
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          ...initialState,
        };
  
      default:
        return state;
    }
  }
  