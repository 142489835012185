import styled from "styled-components";
import React, {  useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";

import Logo from 'assets/images/cog.png';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Dimmer } from "semantic-ui-react";

const OnBoarding = () => {

  const [loading, setLoading] = useState(false)

  const { get_auth, } = useSelector(
      (state) => ({
        get_auth: state.auth,
      }),
      shallowEqual
    );

  return (
    <>

      <Container>
        <Dimmer.Dimmable blurring dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>


          <TopBanner>
            <Left>
              <SubTitle>Welcome, {get_auth?.employee?.first_name}!</SubTitle>
              <Title>Your Innerlogic Pulse </Title>
              <Description>This is an internal app for Innerlogic Employees and partners only</Description>
            </Left>
            <Right>
              <Image src={Logo} />
            </Right>
          </TopBanner>

         
        </Dimmer.Dimmable>
      </Container>

    </>
  )
}


export default OnBoarding


const Container = styled.div`
	width:100%;
  margin-top:30px;

   @media(max-width:768px){
    margin-top:0;
   }
`

const TopBanner = styled.div`
	width:100%;
	height:200px;
	display:flex;
	justify-content:center;
	flex-direction:row;
	font-family: 'Red Hat Display', sans-serif;
	position:relative;
	background-color:rgba(0, 176, 240,0.1);

 
    flex-wrap:wrap;
    height:auto;
  }

`

const Left = styled.div`
	width:50%;
	display:flex;
	flex-direction:column;
	justify-content:center;
	padding:40px;

  @media(max-width:768px){
    width:100%;
  }
`

const Title = styled.div`
	font-size:30px;
	font-weight:bold;
  line-height:1;

  @media(max-width:768px){
    font-size:28px;
  }
`

const Description = styled.div`
	margin-top:30px;
	max-width:800px;
	font-size:18px;
	line-height:1.3;
`

const SubTitle = styled.div`
	color:#545454;
	margin-bottom:20px;
	font-size:16px;
`

const Image = styled.img`
	height:150px;
`

const Right = styled.div`
	width:50%;
	position:relative;
	display:flex;
	align-items:center;
	justify-content:center;

  @media(max-width:768px){
   display:none;
  }
`
