import { call, put, all, takeLatest, takeEvery, take } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_RECOMMENDATION_MODEL_RATING_PENDING,
    GET_RECOMMENDATION_MODEL_RATING,
    SG_GET_RECOMMENDATION_MODEL_RATING,
    DELETE_RECOMMENDATION_MODEL_RATING,
    SG_DELETE_RECOMMENDATION_MODEL_RATING,
    FETCH_RECOMMENDATION_MODEL_RATING,
    SG_FETCH_RECOMMENDATION_MODEL_RATING,
    EDIT_RECOMMENDATION_MODEL_RATING,
    SG_EDIT_RECOMMENDATION_MODEL_RATING,
    ADD_RECOMMENDATION_MODEL_RATING,
    SG_ADD_RECOMMENDATION_MODEL_RATING,
    ALL_ERRORS,
  } from "constants/actions";
  

function* loadmethods(action) {
    yield put({ type: GET_RECOMMENDATION_MODEL_RATING_PENDING });

  try {
    const json = yield call(api.GET_RECOMMENDATION_MODEL_RATING, action.payload);
    yield put({ type: GET_RECOMMENDATION_MODEL_RATING, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_RECOMMENDATION_MODEL_RATING, loadmethods);
}

function* fetchmethods(action) {
  yield put({ type: GET_RECOMMENDATION_MODEL_RATING_PENDING });
  try {
    const json = yield call(api.FETCH_RECOMMENDATION_MODEL_RATING, action.payload);
    yield put({ type: FETCH_RECOMMENDATION_MODEL_RATING, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_RECOMMENDATION_MODEL_RATING, fetchmethods);
}

function* updatemethods(action) {
  yield put({ type: GET_RECOMMENDATION_MODEL_RATING_PENDING });
  try {
    const json = yield call(api.EDIT_RECOMMENDATION_MODEL_RATING, action.payload);
    yield put({ type: EDIT_RECOMMENDATION_MODEL_RATING, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_RECOMMENDATION_MODEL_RATING, updatemethods);
}


function* addmethods(action) {
  yield put({ type: GET_RECOMMENDATION_MODEL_RATING_PENDING });
  try {
    const json = yield call(api.ADD_RECOMMENDATION_MODEL_RATING, action.payload);
    yield put({ type: ADD_RECOMMENDATION_MODEL_RATING, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_RECOMMENDATION_MODEL_RATING, addmethods);
}

function* deletemethods(action) {
  yield put({ type: GET_RECOMMENDATION_MODEL_RATING_PENDING });
  try {
    yield call(api.DELETE_RECOMMENDATION_MODEL_RATING, action.payload);
    yield put({ type: DELETE_RECOMMENDATION_MODEL_RATING, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_RECOMMENDATION_MODEL_RATING, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
