import React from "react";
import { Modal, Header, Button, Icon } from "semantic-ui-react";
const DataStoryRule = ({ showRules, setShowRules }) => {
  return (
    <Modal
      closeIcon
      open={showRules}
      onClose={() => setShowRules(false)}
      onOpen={() => setShowRules(true)}
    >
      <Header>Schedule Template Rules</Header>
      <Modal.Content>
        <div className="doc-content">
          <p>
            Here is a use case example for EVERY case of data story creation.
            This will help determine which UID’s are required and in which
            situation.
          </p>
          <ol>
            
            <li>
            Entire Organization -Select everything from first box (Blue Box) and nothing else
              <ol>
                <li><Icon name='close' color='red' />Primary = [None]</li>
                <li><Icon name='close' color='red' />Category Parent = [None]</li>
                <li><Icon name='close' color='red' />Category options = [None]</li>
              </ol>
            </li>
            <li>
              For every Primary for the org
              <ol>
                <li><Icon name='check' color='green' /> Primary = [None]</li>
                <li><Icon name='close' color='red' /> Category Parent = Department</li>
                <li><Icon name='close' color='red' /> Category options = [None]</li>
              </ol>
            </li>
            <li>
              For every department for the org
              <ol>
                <li><Icon name='close' color='red' /> Primary = [None]</li>
                <li><Icon name='check' color='green' /> Category Parent = Department</li>
                <li><Icon name='close' color='red' /> Category options = [None]</li>
              </ol>
            </li>
            <li>
              For specific department for the org overall
              <ol>
                <li><Icon name='close' color='red' /> Primary = [None]</li>
                <li><Icon name='check' color='green' /> Category Parent = [selected]</li>
                <li><Icon name='check' color='green' /> Category options = [selected]</li>
              </ol>
            </li>
            <li>
              For every department in a specific primary location
              <ol>
                <li><Icon name='check' color='green' /> Primary = [selected]</li>
                <li><Icon name='check' color='green' /> Category Parent = [selected]</li>
                <li><Icon name='close' color='red' /> Category options = [None]</li>
              </ol>
            </li>
            <li>
              For specific department in a specific primary location
              <ol>
                <li><Icon name='check' color='green' /> Primary = [selected]</li>
                <li><Icon name='check' color='green' /> Category Parent = [selected]</li>
                <li><Icon name='check' color='green' /> Category options = [selected]</li>
              </ol>
            </li>
          </ol>
          <p>
            Cases # 2 & 3 are technically the exact same. We’re relying on the
            primary being a category factor itself.
          </p>
        </div>
      </Modal.Content>
      
    </Modal>
  );
};

export default DataStoryRule;
