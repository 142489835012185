

import {
    UPDATE_RECOMMENDATION_MODEL_RATING_PAGE_NUMBER,
    GET_RECOMMENDATION_MODEL_RATING_PENDING,
    GET_RECOMMENDATION_MODEL_RATING,
    DELETE_RECOMMENDATION_MODEL_RATING,
    FETCH_RECOMMENDATION_MODEL_RATING,
    EDIT_RECOMMENDATION_MODEL_RATING,
    ADD_RECOMMENDATION_MODEL_RATING,
    CLEAR_RECOMMENDATION_MODEL_RATING,
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    recommendation_model_rating: [],
    created: false,
    pending: false,
    updated: false,
    pageNumber: 1,
  };
  

export default function RecommendationModelRating(state = initialState, action) {
    switch (action.type) {
      case UPDATE_RECOMMENDATION_MODEL_RATING_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload,
          pending: false,
        };
      case GET_RECOMMENDATION_MODEL_RATING_PENDING:
        return {
          ...state,
          pending: true,
          updated: false,
        };
      case GET_RECOMMENDATION_MODEL_RATING:
        return {
          ...state,
          recommendation_model_rating: action.payload,
          pending: false,
          updated: false,
        };

      case FETCH_RECOMMENDATION_MODEL_RATING:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
          updated: false,
        };
  
  
      case EDIT_RECOMMENDATION_MODEL_RATING:
        return {
          ...state,
          recommendation_model_rating: [...state.recommendation_model_rating, action.payload],
          updated:  action.payload,
          pending: false,
        };
  
      case DELETE_RECOMMENDATION_MODEL_RATING:
        return {
          ...state,
          recommendation_model_rating: state.recommendation_model_rating.filter(
            (row) => row.id !== action.payload.id
          ),
          pending: false,
        };
  
      case ADD_RECOMMENDATION_MODEL_RATING:
        return {
          ...state,
          recommendation_model_rating: [...state.recommendation_model_rating, action.payload],
          response: action.payload.response,
          created: action.payload,
          pending: false,
        };

      case CLEAR_RECOMMENDATION_MODEL_RATING:
        return {
          ...state,
          ...initialState,
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          ...initialState,
        };
  
      default:
        return state;
    }
  }
  