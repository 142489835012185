import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom';

import List from './List';
import Add from './Add';
import Edit from './Edit';
import Details from './Details';
import {  Button,Header } from 'semantic-ui-react'
import ViewRules from './ViewRules';

function ScheduleTemplate() {
    const [showRules, setShowRules] = useState(false)
  return (
    <>
      <Header as='h2' style={{ marginTop: "50px" }}>
        Schedule Report Based on Template
        <Header.Subheader>
          Schedule a report for a closed survey and base it on a template. 
          The schedule can be on role or have a list for whom
          is is scheduled for.
          <Button style={{ marginLeft: '10px' }} onClick={() => setShowRules(!showRules)}>View Rules</Button>
        </Header.Subheader>
      </Header>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="edit/:id" element={<Edit />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="create" element={<Add />} />
      </Routes>

      <ViewRules showRules={showRules} setShowRules={setShowRules} />
    </>
  );
}

export default ScheduleTemplate;
