/**
 * @name Toggle
 * Styled after the figma design
 * TODO: Move this to the component repository.
 */
import React from "react";
import styled from "styled-components";


// Styled Components for the Toggle
const ToggleContainer = styled.div`
  display: flex;
  align-items: center; // Ensures vertical alignment
`;

const ToggleLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: flex;
  height: 18px;
  align-items: center; // This aligns the label vertically
`;

const ToggleDescription = styled.div`
  font-size: 14px;
  color: #888;
  margin-top: 2px; // Add slight margin to push the description a bit lower
`;


const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
  margin-right: 10px; // Adds space between switch and label
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.checked ? "#4caf50" : "#ccc")};
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 15px;
    left: ${(props) => (props.checked ? "23px" : "2px")};
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const HiddenCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// Only apply height to the switch container if there is a label
const SwitchContainer = styled.div`
    display: flex;
    height: ${(props) => (props.includesLabel ? "40px" : "auto")};
`;

const Toggle = ({ label, labelDescription, value, onChange }) => {
  return (
      <ToggleContainer>
          <SwitchContainer includesLabel={label}>
      <Switch>
        <HiddenCheckbox
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
        <Slider checked={value} />
              </Switch>
          </SwitchContainer>
              
          <LabelContainer>
      {label && <ToggleLabel>{label}</ToggleLabel>}
      {labelDescription && <ToggleDescription>{labelDescription}</ToggleDescription>}
          </LabelContainer>
    </ToggleContainer>
  );
};

export default Toggle;