import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_FETCH_SCHEDULE_TEMPLATE,
  SG_ADD_SCHEDULE_TEMPLATE,
} from "constants/actions";
//
import {
  Icon,
  Message,
  Button,
  Header,
  List,
  Input,
  Confirm,
} from "semantic-ui-react";
//
import { format } from "date-fns";
//
import DataForm from "./DataForm";



//
const CreateForm = ({ employee_record_value, setReload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });
  const [orgSelected, setOrgSelected] = useState([]);
  const [valueSelected, setValueSelected] = useState([]);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [valueChanged, setValueChanged] = useState(false);

  function BackArrow() {
    navigate(`/app/schedule-template-report`);
  }

  const { get_schedule_template } = useSelector(
    (state) => ({
      get_schedule_template: state.schedule_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_schedule_template?.created?.id) {
      if (get_schedule_template?.created?.status === 1) {
        navigate(
          `/app/schedule-template-report/`
        );
      } else {
         navigate(
        `/app/schedule-template-report/details/${get_schedule_template?.created?.id}`
      );
      }
     
    }
  }, [get_schedule_template, navigate]);

  useEffect(() => {
    if (get_schedule_template?.deleted) {
      setReload(Math.random());
      const timer1 = setTimeout(() => {
        navigate(`/app/schedule-template-report`);
      }, 500);
      return () => clearTimeout(timer1);
    }
  }, [get_schedule_template, navigate, setReload]);

  useEffect(() => {
    const ErrorList = [];
    if (!templateDetails?.name) {
      ErrorList.push("Enter a Name");
    }
    if (!valueSelected?.id) {
      ErrorList.push("Select a Value Name");
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization");
    }

    setFormErrors(ErrorList);
  }, [templateDetails, valueSelected, orgSelected, valueChanged]);

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        Create New Schedule for Template Report
      </Header>
      {formErrors.length > 0 && !pristine && (
        <Message
          negative
          //   onDismiss={clearErrors}
          header="We need these details!"
          list={formErrors}
        />
      )}

     <DataForm />
   
    </>
  );
};

export default CreateForm;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`;
const SectionCell = styled.div`
  padding: 10px 10px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
`;

const FormLabel = styled.div`
  padding: 0.5em 1em 0.5em 0;
  flex: 1;
`;

const FormInput = styled.div`
  flex: 2;
`;
