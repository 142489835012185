import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// import Page from "components/Page";
import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_SCHEDULE_TEMPLATE,
  UPDATE_SCHEDULE_TEMPLATE_PAGE_NUMBER,
  RESET_SCHEDULE_TEMPLATE,
} from "constants/actions";

import {
  Dimmer,
  Loader,
  Card,
  Pagination,
  Segment,
  Button,
} from "semantic-ui-react";
import SearchList from "./SearchList";

const List = ({ reload }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState("");

  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // if the response is ok 200
    if (searchList) {
      dispatch({
        type: SG_GET_SCHEDULE_TEMPLATE,
        payload: `page=${page}&${searchList}`,
      });
    } else if (searchTerm) {
      dispatch({
        type: SG_GET_SCHEDULE_TEMPLATE,
        payload: `page=${page}&search=${searchTerm}`,
      });
    } else {
      dispatch({
        type: SG_GET_SCHEDULE_TEMPLATE,
        payload: `page=${page}`,
      });
    }
    setLoading(true);
  }, [dispatch, page, searchTerm, reload, searchList]);

  const { get_schedule_template } = useSelector(
    (state) => ({
      get_schedule_template: state.schedule_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_schedule_template?.schedule_template?.results);
    setPage(get_schedule_template?.pageNumber);
    setTotalPages(
      Math.ceil(
        get_schedule_template?.schedule_template?.count /
          get_schedule_template?.schedule_template?.page_size
      )
    );
  }, [get_schedule_template]);

  function handlePageChange(event, value) {
    setPage(value.activePage);
    dispatch({
      type: UPDATE_SCHEDULE_TEMPLATE_PAGE_NUMBER,
      payload: value.activePage,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_schedule_template?.pending);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_schedule_template]);

  function CreateNew() {
    dispatch({
      type: RESET_SCHEDULE_TEMPLATE,
    });
    setLoading(true);
    const timer1 = setTimeout(() => {
      navigate("/app/schedule-template-report/create");
    }, 500);
    return () => clearTimeout(timer1);
  }

  return (
    <Container>
      <SectionDiv lastSection={true}>
        <Button floated="right" color="green" onClick={CreateNew}>
          Create
        </Button>
      </SectionDiv>
      <SectionDiv>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </SectionDiv>
      <SectionDiv>
        <SearchList
          searchList={searchList}
          setSearchList={setSearchList}
          setPage={setPage}
        />
      </SectionDiv>

      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <Card.Group>
          {results &&
            results?.length > 0 &&
            results.map((row) => <RowCard key={row.id} row={row} />)}
        </Card.Group>
      </Segment>
      <SectionDiv>
        <Pagination
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          activePage={page}
          totalPages={totalPages || 0}
          onPageChange={handlePageChange}
        />
      </SectionDiv>
    </Container>
  );
};

export default List;

const Container = styled.div`
  //   min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
  padding: 30px 15px;
`;
