import {
  UPDATE_SCHEDULE_TEMPLATE_PAGE_NUMBER,
  GET_SCHEDULE_TEMPLATE_PENDING,
  GET_SCHEDULE_TEMPLATE,
  DELETE_SCHEDULE_TEMPLATE,
  FETCH_SCHEDULE_TEMPLATE,
  EDIT_SCHEDULE_TEMPLATE,
  ADD_SCHEDULE_TEMPLATE,
  RESET_SCHEDULE_TEMPLATE,
  CLEAR_SCHEDULE_TEMPLATE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  schedule_template: [],
  created: false,
  pending: false,
  updated: false,
  deleted: false,
  pageNumber: 1,
};

export default function ScheduleTemplate(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SCHEDULE_TEMPLATE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
        pending: false,
        created: false,
        updated: false,
        deleted: false,
      };
    case GET_SCHEDULE_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SCHEDULE_TEMPLATE:
      return {
        ...state,
        schedule_template: action.payload,
        pending: false,
        
      };
    case FETCH_SCHEDULE_TEMPLATE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };
    case EDIT_SCHEDULE_TEMPLATE:
      return {
        ...state,
        schedule_template: [...state.schedule_template, action.payload],
        updated: action.payload,
        pending: false,
      };
    case DELETE_SCHEDULE_TEMPLATE:
      return {
        ...state,
        deleted: action.payload,
        pending: false,
      };
    case ADD_SCHEDULE_TEMPLATE:
      return {
        ...state,
        created: action.payload,
        pending: false,
      };
    case RESET_SCHEDULE_TEMPLATE:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_SCHEDULE_TEMPLATE:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
