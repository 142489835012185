import { call, put, all, takeLatest, takeEvery, take } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_RECOMMENDATION_MODELS,
    SG_GET_RECOMMENDATION_MODELS,
    CLEAR_RECOMMENDATION_MODELS,
    GET_RECOMMENDATION_MODELS_PENDING,
    ALL_ERRORS,
  } from "constants/actions";
  

  function* loadmethods(action) {
    yield put({ type: GET_RECOMMENDATION_MODELS_PENDING });

  try {
    const json = yield call(api.GET_RECOMMENDATION_MODELS, action.payload);
    yield put({ type: GET_RECOMMENDATION_MODELS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_RECOMMENDATION_MODELS, loadmethods);
}

export default function* index() {
    yield all([
      methodLoad(),
    ]);
  }
  